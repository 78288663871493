<template>
  <v-row>
    <v-dialog v-model="HintYoga" scrollable persistent max-width="700px">
      <v-card>
        <v-toolbar dense flat
          ><h3>Petunjuk Permainan</h3>
          <v-spacer></v-spacer>
          <h4 v-if="!close">Silakan tunggu... {{ counting }}</h4>
          <v-btn text v-if="close" @click="$emit('false')"
            >tutup <v-icon color="red">mdi-close keluar</v-icon></v-btn
          >
        </v-toolbar>
        <div class="pa-5 d-flex flex-column align-center">
          <p>
            Pada permainan ini Anda diharuskan menyusun potongan gambar sesuai
            dengan pola yang tersedia. <b>Klik</b> setiap potongan dan
            <b>pindahkan</b> pada pola tersebut. Jika sudah selesai,
            <b>klik</b> tanda panah untuk menyelesaikan pola lainnya.
          </p>
          <img src="../../../assets/img/YogaView.png" height="300px" alt="" />
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "YogaHint",
  props: ["HintYoga"],
  data() {
    return {
      close: false,
      counting: null
    };
  },
  mounted() {
    this.timerClose();
  },
  methods: {
    timerClose() {
      let sec = 15;
      console.log(sec);
      let min = setInterval(() => {
        sec--;
        this.counting = sec;
        if (sec === 0) {
          this.close = true;
          clearInterval(min);
        }
      }, 1000);
    }
  }
};
</script>

<style></style>
